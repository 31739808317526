<template>
  <div>
    <FrmDatosEstudiante v-if="inscripcion.codigo_alumno>0"
                        ref="frmDatosEstudiante"
                        :facultades="facultades" :carreras="carreras"
                        :nacionalidades="nacionalidades" :inscripcion="inscripcion">
    </FrmDatosEstudiante>
    <hr class="border-primary border-1"/>
    <div class="row">
      <div class="col-10">
        <template v-if="contrato">
        <div class="table-responsive">
          <table cellpadding="0" class="table table-bordered table-sm m-0">
            <tr>
              <td colspan="6" class="text-center">
                    <strong>Fechas de Vencimiento y Pago de Multas</strong>
                  <i class="ml-3 ms-3">Costo Multa (Bs.): </i> <strong>{{ multa }}</strong>
                    <input v-model="multa" class="form-control-plaintext form-control-sm p-0 m-0 text-bold" readonly="" type="hidden"/>
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="text-center"><strong>1º Cuota</strong></td>
              <td class="text-center"><strong>2º Cuota</strong></td>
              <td class="text-center"><strong>3º Cuota</strong></td>
              <td class="text-center"><strong>4º Cuota</strong></td>
              <td class="text-center"><strong>5º Cuota</strong></td>
            </tr>
            <tr>
              <td>Fecha</td>
              <td class="p-0"><input v-model="cuota1.fecha_pago" class="form-control-plaintext form-control-sm text-center"
                                     type="date" readonly/></td>
              <td class="p-0"><input v-model="cuota2.fecha_pago" class="form-control-plaintext form-control-sm text-center"
                                     type="date" readonly/></td>
              <td class="p-0"><input v-model="cuota3.fecha_pago" class="form-control-plaintext form-control-sm text-center"
                                     type="date" readonly/></td>
              <td class="p-0"><input v-model="cuota4.fecha_pago" class="form-control-plaintext form-control-sm text-center"
                                     type="date" readonly/></td>
              <td class="p-0"><input v-model="cuota5.fecha_pago" class="form-control-plaintext form-control-sm text-center"
                                     type="date" readonly/></td>
            </tr>
            <tr>
              <td>Días de Multa</td>
              <td class="p-0"><input v-model="cuota1.diasmulta" class="form-control-plaintext form-control-sm text-center"
                                     max="3600" min="0" step="1" type="number" readonly/>
              </td>
              <td class="p-0"><input v-model="cuota2.diasmulta" class="form-control-plaintext form-control-sm text-center"
                                     max="3600" min="0" step="1" type="number" readonly/>
              </td>
              <td class="p-0"><input v-model="cuota3.diasmulta" class="form-control-plaintext form-control-sm text-center"
                                     max="3600" min="0" step="1" type="number" readonly/>
              </td>
              <td class="p-0"><input v-model="cuota4.diasmulta" class="form-control-plaintext form-control-sm text-center"
                                     max="3600" min="0" step="1" type="number" readonly/>
              </td>
              <td class="p-0"><input v-model="cuota5.diasmulta" class="form-control-plaintext form-control-sm text-center"
                                     max="3600" min="0" step="1" type="number" readonly/>
              </td>
            </tr>
            <tr>
              <td>Multa (Bs.)</td>
              <td class="p-0"><input v-model="cuota1.multa" class="form-control-plaintext form-control-sm text-center"
                                     max="10000" min="0" step="0.1" type="number" readonly/>
              </td>
              <td class="p-0"><input v-model="cuota2.multa" class="form-control-plaintext form-control-sm text-center"
                                     max="10000" min="0" step="0.1" type="number" readonly/>
              </td>
              <td class="p-0"><input v-model="cuota3.multa" class="form-control-plaintext form-control-sm text-center"
                                     max="10000" min="0" step="0.1" type="number" readonly/>
              </td>
              <td class="p-0"><input v-model="cuota4.multa" class="form-control-plaintext form-control-sm text-center"
                                     max="10000" min="0" step="0.1" type="number" readonly/>
              </td>
              <td class="p-0"><input v-model="cuota5.multa" class="form-control-plaintext form-control-sm text-center"
                                     max="10000" min="0" step="0.1" type="number" readonly/>
              </td>
            </tr>

            <tr>
              <td colspan="6" class="text-center"><strong>Monto pagado</strong></td>
            </tr>
            <tr>
              <td>Monto Contrato</td>
              <td class="px-0 text-center">{{cuota1.monto}}</td>
              <td class="px-0 text-center">{{cuota2.monto}}</td>
              <td class="px-0 text-center">{{cuota3.monto}}</td>
              <td class="px-0 text-center">{{cuota4.monto}}</td>
              <td class="px-0 text-center">{{cuota5.monto}}</td>
            </tr>
            <tr>
              <td>Pagado (Bs)</td>
              <td class="px-0 text-center"><strong :class="(cuota1.pagado < cuota1.monto?'text-danger':(cuota1.pagado > cuota1.monto?'text-primary':'text-success'))">{{cuota1.pagado}}</strong></td>
              <td class="px-0 text-center"><strong :class="(cuota2.pagado < cuota2.monto?'text-danger':(cuota2.pagado > cuota2.monto?'text-primary':'text-success'))">{{cuota2.pagado}}</strong></td>
              <td class="px-0 text-center"><strong :class="(cuota3.pagado < cuota3.monto?'text-danger':(cuota3.pagado > cuota3.monto?'text-primary':'text-success'))">{{cuota3.pagado}}</strong></td>
              <td class="px-0 text-center"><strong :class="(cuota4.pagado < cuota4.monto?'text-danger':(cuota4.pagado > cuota4.monto?'text-primary':'text-success'))">{{cuota4.pagado}}</strong></td>
              <td class="px-0 text-center"><strong :class="(cuota5.pagado < cuota5.monto?'text-danger':(cuota5.pagado > cuota5.monto?'text-primary':'text-success'))">{{cuota5.pagado}}</strong></td>
            </tr>
          </table>
        </div>
        </template>
        <template v-else>
          <ul>
            <li>No es posible calcular las <strong class="text-danger">multas</strong> para el estudiante</li>
            <li>Primero registre el <strong>contrato</strong> del estudiante para la gestión <strong>{{ $parent.factura.gestion }}</strong>.</li>
          </ul>
        </template>
      </div>
      <div class="col-2 ps-0 pl-0 text-center">
        <div class="d-grid gap-2">
          <div class="btn-group" role="group" aria-label="Basic example" v-if="!mostrarPagos">
            <button type="button" class="btn btn-success">
              <font-awesome-icon icon="fa-eye"/></button>
            <button type="button" class="btn btn-success btn-block" @click="mostrarPagos=true">
              Ver otros pagos<br/>del Estudiante</button>
          </div>
          <div class="btn-group" role="group" aria-label="Basic example" v-if="mostrarPagos">
            <button type="button" class="btn btn-info">
              <font-awesome-icon icon="fa-eye-slash"/></button>
            <button type="button" class="btn btn-info btn-block" @click="mostrarPagos=false">
              Ocultar otros pagos<br/>del Estudiante</button>
          </div>
        </div>
      </div>
    </div>
    <FrmPagosEstudiante v-if="inscripcion.codigo_alumno>0 && mostrarPagos"
                        ref="frmPagosEstudiante"
                        :codigo_alumno="inscripcion.codigo_alumno">
    </FrmPagosEstudiante>
  </div>
</template>

<script>
import FrmDatosEstudiante from "@/views/facturaenlinea/frmdatosestudiante";
import FrmPagosEstudiante from "@/views/facturaenlinea/frmpagosestudiante";

var modalPdf;

export default {
  name: 'frmestudiantemultas',
  components: {
    FrmDatosEstudiante,
    FrmPagosEstudiante
  },
  props: {
    inscripcion: Object,
    facultades: Array,
    carreras: Object,
    nacionalidades:Array,
    cuota0: Object,
    cuota1: Object,
    cuota2: Object,
    cuota3: Object,
    cuota4: Object,
    cuota5: Object,
    multa: Number,
    contrato: Boolean,
  },
  data: () => {
    return {
      mostrarPagos:false
    }
  },
  mounted: function () {
    modalPdf = this;
  }
}
</script>